import { currentAccount } from 'services/firebase'
import axios from 'axios'

export default async function(history) {
  let user

  if (typeof window !== 'undefined') {
    if (window.location.hostname === 'localhost') {
      return
    }

    history.listen(async (location, action) => {
      if (!user) {
        user = await currentAccount()
      } else {
        axios
          .post('https://us-central1-redrive-site.cloudfunctions.net/production-users-big-query', {
            id: user.id,
            email: user.email,
            page: location.pathname || 'unknow',
            date: new Date(),
          })
          .catch(err => console.log('err', err))
      }
    })
  }
}
