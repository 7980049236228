import 'antd/lib/style/index.less' // antd core styles
import 'remixicon/fonts/remixicon.css'
import './components/kit/vendors/antd/themes/dark.less' // dark theme antd components
import './components/kit/vendors/antd/themes/default.less' // default theme antd components
import './global.scss' // app & third-party component styles

import { createBrowserHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, compose, createStore } from 'redux'
// import { logger } from 'redux-logger'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'

import { ChatFlowProvider } from './pages/chatflowlist/hook/chatflow'

// import ReactHeap from 'reactjs-heap'
import { QueryClient, QueryClientProvider } from 'react-query'
import { run as runCheckuser } from './checkDefaulterUser'
import Localization from './localization'
import reducers from './redux/reducers'
import sagas from './redux/sagas'
import Router from './router'
import * as serviceWorker from './serviceWorker'
import userTracking from './userTracking'
import ConfigNotification from 'ConfigNotification'
runCheckuser()

// Sentry.init({
//   dsn: "https://70463ac82dab482b8e46ea1662f6bc32@o1021264.ingest.sentry.io/4505087308529664",
//   integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

// middlewared
const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]
// ReactHeap.initialize('3915926123')

// trackeia a navegacao do usuario durante toda a navegacao
userTracking(history)

// if (process.env.NODE_ENV === 'development') {
//   middlewares.push(logger)
// }
const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)
export const queryClient = new QueryClient()

ReactDOM.render(
  // <GoogleOAuthProvider clientId="480141464173-jo1ahvdtv5c8cve8o7flkkbt0ojera59.apps.googleusercontent.com">
  <QueryClientProvider client={queryClient}>
    <ConfigNotification>
      <Provider store={store}>
        <ChatFlowProvider>
          <Localization>
            <Router history={history} />
          </Localization>
        </ChatFlowProvider>
      </Provider>
    </ConfigNotification>
  </QueryClientProvider>,
  // </GoogleOAuthProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
export { history, store }
