import { notification } from 'antd'
import { history } from 'index'
import { useEffect } from 'react'
import { db, firebaseAuth } from 'services/firebase'

const { __ } = require('components/ChangeLanguage').getMessage('settings/whatsapp')

const useSessionCheck = (sessionType, getSessionMessage) => {
  useEffect(() => {
    let snapshotListener = () => {}
    let intervalId
    const unsubscribeAuth = firebaseAuth.onAuthStateChanged(async user => {
      if (user) {
        await new Promise(resolve => setTimeout(resolve, 5000))

        const connectionList = {}
        const notifiedSessions = new Set() // Conjunto para armazenar sessões já notificadas
        const delayTime = sessionType === 'telegram-api-config' ? 30000 : 1000

        snapshotListener = db
          .collection('users')
          .doc(user.uid)
          .collection(sessionType)
          .onSnapshot(snapshot => {
            snapshot.forEach(doc => {
              const session = doc.data()
              const sessionId = doc.id

              if (sessionType === 'telegram-api-config') {
                if (session.name && session.isConnected) {
                  const key = Date.now()

                  // Verifica se já notificou esta sessão
                  if (!notifiedSessions.has(sessionId)) {
                    notification.warning({
                      key,
                      duration: 5,
                      message: getSessionMessage(session),
                      onClick: () => {
                        history.push('/settings')
                        notification.close(key)
                      },
                    })
                    notifiedSessions.add(sessionId) // Adiciona ao conjunto
                  }
                }
              }
            })
          })
      } else {
        snapshotListener?.()
      }
    })

    return () => {
      snapshotListener?.()
      unsubscribeAuth()
      if (intervalId) clearInterval(intervalId)
    }
  }, [sessionType, getSessionMessage])
}

const ConectionChetTelegram = () => {
  useSessionCheck('telegram-api-config', session => {
    return 'Bot telegram esta desconectado. Clique aqui para reconectar'
  })

  return null
}

export default ConectionChetTelegram
